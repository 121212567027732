import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

type ImageData = {
  name: string;
  altText: string;
  frameId: string;
  id: string;
};

type Data = {
  isLoading: boolean;
  errorFetchingImages: boolean;
  imagesReferenceFrame: string;
  images: ImageData[];
};

type OptionIdsHashMap = Record<string, Data>;

export type ContentImagesHashMap = Record<string, OptionIdsHashMap>;

export const contentImagesStateSegment =
  stateSegmentWithReset<ContentImagesHashMap>({});
contentImagesStateSegment.debugLabel = 'contentImagesStateSegment';
