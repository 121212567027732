import axios, { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Logger from '../utils/logger';
import { CLIENT_ID, AUTH_URL, ACCESS_TOKEN_URL } from '../constants/ssoURLs';
import { GET_USER_INFO } from '../constants/apiRoutes';
import { httpClient } from '../utils/httpClient';
import { APIUserInfoResponse } from '../types/Users';

const REDIRECT_URI: string = `${window.location.origin}/callback`;

export const setPrimaryLanguage = (languageCode: string) => {
  return sessionStorage.setItem(
    'pfz-cggenai-user-primary-language',
    languageCode
  );
};

export const getPrimaryLanguage = () => {
  return sessionStorage.getItem('pfz-cggenai-user-primary-language') || 'en';
};

/*
  get the original url
*/
export const getOriginalEncodedUrl = () => {
  // Original URL
  const originalUrl = window.location.href;
  // return the encoded url
  return encodeURIComponent(originalUrl);
};

/**
 * Generates a random string using crypto API.
 * @returns {string} A random string.
 */
export const generateRandomString = (): string => {
  const array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (val: number) =>
    `0${val.toString(16)}`.slice(-2)
  ).join('');
};

/**
 * Generates code challenge from the given verifier.
 * @param {string} verifier The code verifier.
 * @returns {Promise<string>} The code challenge.
 */
export const generateCodeChallenge = async (
  verifier: string
): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const digest = await crypto.subtle.digest('SHA-256', data);
  const arrayFromUint8 = Array.from(new Uint8Array(digest));
  return btoa(String.fromCharCode(...arrayFromUint8))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

/**
 * Initiates the PKCE flow to retrieve the initial tokens.
 */
export const startPKCEFlow = async (): Promise<void> => {
  const codeVerifier = generateRandomString();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  const state = getOriginalEncodedUrl();
  sessionStorage.setItem('pfz-cggenai-token-codeverifier', codeVerifier);
  // store the state of the original url
  sessionStorage.setItem('oauth_state', state);
  window.location.href = `${AUTH_URL}?response_type=code&client_id=${CLIENT_ID}&code_challenge=${codeChallenge}&code_challenge_method=S256&redirect_uri=${REDIRECT_URI}&state=${state}`;
};

/**
 * Refreshes the access token using the provided refresh token.
 * It stores the retrieved access token and related info in the session storage.
 * @param {string} refreshToken - The refresh token used to get a new access token.
 * @returns {Promise<void>} - Returns a promise that resolves with nothing if successful, otherwise throws an error.
 * @throws Will throw an error if there's an issue refreshing the access token.
 */
export const refreshAccessToken = async (
  refreshToken: string
): Promise<void> => {
  const state = getOriginalEncodedUrl();

  sessionStorage.setItem('oauth_state', state);

  const payload = {
    client_id: CLIENT_ID,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  };

  // Convert the payload to URL encoded format for the content type application/x-www-form-urlencoded
  const formData = new URLSearchParams(payload).toString();

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const response = await axios.post(ACCESS_TOKEN_URL, formData, config);

    if (get(response, 'data.access_token')) {
      const accessToken = get(response, 'data.access_token', '');
      const idToken = get(response, 'data.id_token', '');
      const expiresIn = get(response, 'data.expires_in', 3600);
      const expiresAt = Date.now() + expiresIn * 1000;

      sessionStorage.setItem('pfz-cggenai-token-access', accessToken);
      sessionStorage.setItem('pfz-cggenai-token-id', idToken);
      sessionStorage.setItem('pfz-cggenai-token-expiry', expiresAt.toString());
    } else {
      throw new Error(
        get(response, 'data.message', 'Error refreshing access token')
      );
    }
  } catch (error) {
    throw new Error(
      get(error, 'response.data.message', 'Error refreshing access token')
    );
  }
};

/**
 * Exchanges the provided authorization code for an access token and other related tokens.
 * It stores the retrieved tokens and related info in the session storage.
 * @param {string} code - The authorization code received during the PKCE flow.
 * @returns {Promise<void>} - Returns a promise that resolves with nothing if successful, otherwise throws an error.
 * @throws Will throw an error if the code verifier is missing or there's an issue exchanging the code for tokens.
 */
export const exchangeCodeForToken = async (code: string): Promise<void> => {
  const codeVerifier = sessionStorage.getItem('pfz-cggenai-token-codeverifier');

  if (!codeVerifier) {
    throw new Error('Code verifier is missing.');
  }

  const payload = {
    client_id: CLIENT_ID,
    grant_type: 'authorization_code',
    code,
    code_verifier: codeVerifier,
    redirect_uri: REDIRECT_URI,
  };

  // Convert the payload to URL encoded format for the content type application/x-www-form-urlencoded
  const formData = new URLSearchParams(payload).toString();

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const response = await axios.post(ACCESS_TOKEN_URL, formData, config);

    if (get(response, 'data.access_token')) {
      const accessToken = get(response, 'data.access_token', '');
      const idToken = get(response, 'data.id_token', '');
      const expiresIn = get(response, 'data.expires_in', 3600);
      const refreshToken = get(response, 'data.refresh_token', '');
      const expiresAt = Date.now() + expiresIn * 1000;

      sessionStorage.setItem('pfz-cggenai-token-access', accessToken);
      sessionStorage.setItem('pfz-cggenai-token-id', idToken);
      sessionStorage.setItem('pfz-cggenai-token-refresh', refreshToken);
      sessionStorage.setItem('pfz-cggenai-token-expiry', expiresAt.toString());
      sessionStorage.removeItem('pfz-cggenai-token-codeverifier');
    } else {
      throw new Error(
        get(response, 'data.message', 'Error exchanging code for token')
      );
    }
  } catch (error) {
    throw new Error(
      get(error, 'response.data.message', 'Error exchanging code for token')
    );
  }
};

/**
 * Fetches user information from the AWS v1/user/info.
 * It stores the retrieved user's given name in the session storage.
 * @returns {Promise<void>} - Returns a promise that resolves with nothing if successful, otherwise throws an error.
 * @throws Will throw an error if the access token is missing or there's an issue fetching the user information.
 */
export const fetchUserInfo = async (): Promise<void> => {
  try {
    const response = await httpClient.get<APIUserInfoResponse>(GET_USER_INFO);
    const { data, success } = response as APIUserInfoResponse;

    let givenName = 'User';
    let role = 'GENERAL';
    if (success && data) {
      let fullName = '';
      const firstName = get(data, 'user.firstName', '');
      const lastName = get(data, 'user.lastName', '');
      if (!isEmpty(firstName)) {
        fullName += firstName;
      }
      if (!isEmpty(lastName)) {
        fullName += ` ${lastName}`;
      }

      if (!isEmpty(fullName)) {
        givenName = fullName;
      }
      role = get(data, 'user.roles[0].roleCode', 'GENERAL');
    }

    setPrimaryLanguage('en');

    if (givenName && role) {
      sessionStorage.setItem('pfz-cggenai-user-givenname', givenName);
      sessionStorage.setItem('pfz-cggenai-user-role', role);
    } else {
      Logger.error(
        new Error(
          'Given name or user role is not available in the user info response.'
        )
      );
    }
  } catch (error) {
    Logger.error(
      new Error(
        get(error, 'response.data.message', 'Error fetching user information')
      )
    );
  }
};
