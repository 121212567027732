import isEmpty from 'lodash/isEmpty';
import {
  ALL_MODULES_FILTER,
  EMAIL_ATTRIBUTES_NAME,
  ImageInfoConfig,
  recommendImageInfoConfigs,
} from '../constants/common';
import { translate } from '../localization';
import { ContentTypeImage, ImageRecomendationInfoType } from '../types/Content';
import { CommonDataType, EmailModulesData } from '../types/EmailModules';
import { ImageRecommendationStatus } from '../types/Images';

type FieldWithPossiblyUndefined<T, Key> =
  | GetFieldType<Exclude<T, undefined>, Key>
  | Extract<T, undefined>;

type GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? '0' extends keyof T // tuples have string keys, return undefined if K is not in tuple
    ? undefined
    : number extends keyof T
    ? T[number]
    : undefined
  : undefined;

export type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ? FieldWithPossiblyUndefined<T[Left], Right>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
      ? FieldWithPossiblyUndefined<
          | GetIndexedField<Exclude<T[FieldKey], undefined>, IndexKey>
          | Extract<T[FieldKey], undefined>,
          Right
        >
      : undefined
    : undefined
  : P extends keyof T
  ? T[P]
  : P extends `${infer FieldKey}[${infer IndexKey}]`
  ? FieldKey extends keyof T
    ?
        | GetIndexedField<Exclude<T[FieldKey], undefined>, IndexKey>
        | Extract<T[FieldKey], undefined>
    : undefined
  : undefined;

type OptionsType = {
  id: string;
  label: string;
  name: string;
};

export function getValue<
  TData,
  TPath extends string,
  TDefault = GetFieldType<TData, TPath>,
>(
  data: TData,
  path: TPath,
  defaultValue?: TDefault
): GetFieldType<TData, TPath> | TDefault {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    /* eslint-disable */
    .reduce<GetFieldType<TData, TPath>>(
      (value, key) => (value as any)?.[key],
      data as any
    );

  return value !== undefined ? value : (defaultValue as TDefault);
}

export const generateUniqueId = (strLength: number): string => {
  const array = new Uint32Array(strLength);
  window.crypto.getRandomValues(array);
  return Array.from(array, (val: number) =>
    `0${val.toString(16)}`.slice(-2)
  ).join('');
};

export const getWFDetails = () => {
  const params = new URLSearchParams(window.location.search);
  const taskId = params.get('taskId') || '';
  const projectId = params.get('projectId') || '';
  return {
    isWFDetailsAvailable: taskId !== '' && projectId !== '',
    taskId,
    projectId,
  };
};

export const getPreselectedValue = (options: OptionsType[], value: string) =>
  options.find(
    (option: OptionsType) =>
      option.name?.toLowerCase() === value?.toLocaleLowerCase()
  )?.id || '';

export const getSelectedValueName = (
  options: OptionsType[],
  selectedName: string
) =>
  options.find(
    (option: OptionsType) =>
      option.name?.toLowerCase() === selectedName?.toLocaleLowerCase()
  )?.name || '';

export const getImageRecommendationStatus = (
  isImageLoading: boolean,
  images: ContentTypeImage[],
  isRequested: boolean,
  errorFetchingImages: boolean
): ImageRecommendationStatus => {
  if (errorFetchingImages) return ImageRecommendationStatus.ERROR;

  if (!isImageLoading && !isRequested && images.length === 0)
    return ImageRecommendationStatus.IDLE;

  if (!isImageLoading && isRequested && images.length === 0)
    return ImageRecommendationStatus.NO_IMAGES;

  if (isImageLoading) return ImageRecommendationStatus.LOADING;

  if (!isImageLoading && images.length > 0)
    return ImageRecommendationStatus.LOADED;
  return ImageRecommendationStatus.IDLE;
};

export const getImageRecommendationInfo = (
  isImageRecomendationAllowed: boolean,
  imagesLength: number,
  imageRecommendationStatus: ImageRecommendationStatus,
  noOfImagesExpected: number
): boolean => {
  return (
    isImageRecomendationAllowed &&
    (imageRecommendationStatus === ImageRecommendationStatus.LOADED ||
      imageRecommendationStatus === ImageRecommendationStatus.NO_IMAGES) &&
    (imagesLength === 0 || imagesLength < noOfImagesExpected)
  );
};

export const getImageInfoConfig = (
  isImageInfoVisible: boolean,
  imagesLength: number,
  expectedNoOfImages: number
): ImageRecomendationInfoType => {
  if (isImageInfoVisible) {
    if (imagesLength === 0) {
      return (
        recommendImageInfoConfigs.find(
          (el: ImageRecomendationInfoType) =>
            el.type === ImageInfoConfig.ZERO_IMAGES
        ) ?? ({} as ImageRecomendationInfoType)
      );
    } else if (imagesLength > 0 && imagesLength < expectedNoOfImages) {
      return (
        recommendImageInfoConfigs.find(
          (el: ImageRecomendationInfoType) =>
            el.type === ImageInfoConfig.INSUFFICIENT_IMAGES
        ) ?? ({} as ImageRecomendationInfoType)
      );
    }
  }

  return {} as ImageRecomendationInfoType;
};

export const getInitials = (fullName: string) => {
  // Check for empty param
  if (!fullName) return '';

  // Trim any leading or trailing whitespace from the name.
  const trimmedName = fullName.trim();

  // Split the name into words using spaces as delimiters.
  const words = trimmedName.split(' ');

  // Check if there are any words in the name.
  if (!words.length) {
    return ''; // Return empty string if no words found
  }

  // Extract the first letter from the first and last words.
  const firstLetter = words[0][0].toUpperCase();
  const lastLetter =
    (words.length > 1 && words?.[words.length - 1]?.[0]?.toUpperCase()) || '';

  // Combine the first letters to form the initials.
  return firstLetter + lastLetter;
};

export const getConvertedName = (name: string) =>
  name
    .split(' ')
    .map((word) => word.toLowerCase().replace(/\(|\)/g, ''))
    .join('-');

export const createUniqueModuleKey = (
  name: string,
  count: number = 1
): string => {
  return `${getConvertedName(name)}_${count}`;
};

export const createModulesFilterOptions = (
  modules: EmailModulesData[]
): CommonDataType[] => {
  // Track seen labels to avoid duplicates
  const seenLabels = new Set<string>();
  let segmentCount = 0; // For generating unique ids

  const defaultFilterOption: CommonDataType[] = [
    {
      id: ALL_MODULES_FILTER,
      name: translate('all'),
      label: translate('all'),
    },
  ];

  return modules.reduce((acc: CommonDataType[], module) => {
    const { segment } = module;

    // If the label hasn't been processed yet, add it to the result array
    if (!isEmpty(segment) && !seenLabels.has(segment)) {
      segmentCount += 1;
      const newModule = {
        id: `${segment}-${segmentCount}`,
        label: segment,
        name: segment,
      };

      acc.push(newModule);
      seenLabels.add(segment); // Mark this label as processed
    }

    return acc;
  }, defaultFilterOption);
};

const getSegment = (name: string) => {
  if (name.indexOf('header') !== -1) return 'header';
  if (name.indexOf('body') !== -1) return 'body';
  if (name.indexOf('admin') !== -1) return 'admin';
  return '';
};

export const formatModulesResponse = (modules: CommonDataType[]) => {
  const segmentedModules: EmailModulesData[] = [];
  modules.forEach((module) => {
    segmentedModules.push({
      ...module,
      isEmailAttributes: module.name === EMAIL_ATTRIBUTES_NAME,
      moduleKey: getConvertedName(module.name),
      segment: getSegment(module.name?.toLowerCase()),
    });
  });
  return segmentedModules;
};

export const getContentSessionId = () =>
  sessionStorage.getItem('pfz-cggenai-content-session-id') || '';
