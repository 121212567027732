/**
 * @description This file is to track the usage of add images feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackAddImagesUsage
 * @description This method tracks the usage of add images feature
 * @param {string} brand (brand name)  [brand used to create the content]
 * @param {string} channel (channel name) [channel used to create the content]
 */
export const trackAddImagesUsage = (brand: string, channel: string) => {
  trackFormInput(
    ANALYTICS.ADD_IMAGES_FORM_NAME,
    ANALYTICS.NUMBER_ONE,
    ANALYTICS.ADD_IMAGES_FORM_QUESTION,
    `${brand} and ${channel}`,
    ANALYTICS.NUMBER_ONE
  );
};

export const trackRecommendImageDeficient = (isDataDeficient: string) => {
  trackFormInput(
    ANALYTICS.ADD_IMAGES_FORM_NAME,
    ANALYTICS.NUMBER_ONE,
    ANALYTICS.DATA_DEFICIENCY_FORM_QUESTION,
    isDataDeficient,
    ANALYTICS.NUMBER_ONE
  );
};
