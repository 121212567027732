import { FC, Suspense, lazy, useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { ROLE } from '../../../constants/common';
import AccessForbidden from '../AccessForbidden';
import PageLoader from '../../molecules/PageLoader';
import * as majoraActions from '../../../store/markettingContentGeneration/actions/majorProcessActions';

const MarketingDashboard = lazy(() => import('../MarketingDashboard'));
const UserManagement = lazy(() => import('../UserManagement'));

interface MarketingApplicationProps {
  isLoadMarketingWidget?: boolean;
  isLoadAccessWidget?: boolean;
}

const MarketingApplication: FC<MarketingApplicationProps> = ({
  isLoadAccessWidget = false,
  isLoadMarketingWidget = false,
}) => {
  const isAdminUser =
    sessionStorage.getItem('pfz-cggenai-user-role') === ROLE.ADMIN;

  useEffect(() => {
    /* we need to clear app data explicitly as jotai doesnot clears state on route change */
    return () => {
      majoraActions.clearAllValues();
    };
  }, []);

  return (
    <MainLayout>
      <Suspense fallback={<PageLoader />}>
        {isLoadMarketingWidget && <MarketingDashboard />}
        {isLoadAccessWidget &&
          (isAdminUser ? <UserManagement /> : <AccessForbidden />)}
      </Suspense>
    </MainLayout>
  );
};

MarketingApplication.displayName = 'MarketingApplication';

export default MarketingApplication;
