import { ANALYTICS } from '../../constants/common';
import { trackCustomLinkUsage, trackFormInput } from './analyticEvents';

/**
 * @method trackPageLevelTranslation
 * @description this method tracks the page level translation
 * @param {string} formName (form name) [name of the form]
 * @param {string} selectedLanguage (selected language) [selected Language value]
 */
export const trackPageLevelTranslation = (
  formName: string,
  selectedLanguage: string
) => {
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'language',
    `${selectedLanguage}`,
    '1'
  );
};

/**
 * @method trackLanguageCompare
 * @description This method tracks the compare mode click for comparing two different language
 */
export const trackLanguageCompare = (languageCompareName: string) => {
  trackCustomLinkUsage(languageCompareName, 'internal');
};
