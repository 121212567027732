import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { useAtomValue as useStateSegmentValue } from 'jotai';
import { emitCustomEvent } from 'react-custom-events';
import {
  RiAddFill,
  RiAiGenerate,
  RiArrowLeftDoubleFill,
  RiArrowRightDoubleFill,
  // RiHistoryLine,
  // RiHomeLine,
  RiSettings3Line,
} from '@remixicon/react';
import styles from './NavigationPanel.module.css';
import {
  ACTIVE_MENU_BY_ROUTE,
  MENU_NAMES,
  ROLE,
  ANALYTICS,
} from '../../../constants/common';
import { trackAdminPageVisit } from '../../../services/Analytics';
import NavSection from '../../molecules/NavSection';
import PfizerLogo from '../../atoms/PfizerLogo';
import Button from '../../atoms/Button';
import { translate } from '../../../localization';
import { startNewStateSegment } from '../../../store/markettingContentGeneration/stateSegments/startNewStateSegment';

interface NavigationPanelProps {
  isExpanded: boolean;
  onNavToggle: (value: boolean) => void;
  activeMenu: string;
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
}

const NavigationPanel: FC<NavigationPanelProps> = ({
  isExpanded,
  onNavToggle,
  activeMenu,
  setActiveMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminUser =
    sessionStorage.getItem('pfz-cggenai-user-role') === ROLE.ADMIN;

  const { allowStartNew } = useStateSegmentValue(startNewStateSegment);

  const handleNavMenuClicked = (name: string) => {
    if (name === 'access' && isAdminUser) {
      navigate('/user-management', {
        state: {
          isNavPanelOpen: isExpanded,
          selectedMenu: name,
        },
      });
      setActiveMenu(name);
      // track analytics for  admin page visit
      trackAdminPageVisit(ANALYTICS.ADMIN_PAGE_VISIT_NAME);
    } else {
      switch (name) {
        case 'pfizer-logo':
        case 'create': {
          navigate('/generate-content', {
            state: {
              isNavPanelOpen: isExpanded,
            },
          });
          setActiveMenu('create');
          break;
        }
        case 'create-new': {
          emitCustomEvent('start-new');
          break;
        }
        case 'close-sidebar': {
          onNavToggle(!isExpanded);
          break;
        }
        case 'history':
        case 'home': {
          setActiveMenu(name);
          break;
        }
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const pathname = get(location, 'pathname', '');
    if (pathname) setActiveMenu(ACTIVE_MENU_BY_ROUTE[pathname]);
  }, []);

  const userMenuItems = [
    // {
    //   icon: <RiHomeLine size={24} />,
    //   text: translate('home'),
    //   name: MENU_NAMES.HOME,
    // },
    {
      icon: <RiAiGenerate size={24} />,
      text: translate('create'),
      name: MENU_NAMES.CREATE,
    },
    // {
    //   icon: <RiHistoryLine size={24} />,
    //   text: translate('history'),
    //   name: MENU_NAMES.HISTORY,
    // },
  ];

  const adminMenuItems = [
    {
      icon: <RiSettings3Line size={24} />,
      text: translate('access'),
      name: MENU_NAMES.ACCESS,
    },
  ];

  const footerMenuItems = [
    {
      icon: isExpanded ? (
        <RiArrowLeftDoubleFill size={24} />
      ) : (
        <RiArrowRightDoubleFill size={24} />
      ),
      text: translate('close_sidebar'),
      name: MENU_NAMES.CLOSE_SIDE_BAR,
    },
  ];

  // Navbar component
  return (
    <nav
      className={`${styles.navbar} ${isExpanded ? '' : styles.collapsed}`}
      aria-label="Main Navigation"
      key="pf-nav-menu"
    >
      <div
        className={`${styles['brand-nav-section']} ${
          isExpanded ? '' : styles.collapsed
        }`}
      >
        <PfizerLogo
          isExpanded={isExpanded}
          onClick={handleNavMenuClicked}
          className={styles['brand-logo']}
        />
        <Button
          id="create-new"
          className={`${styles['create-new']} ${
            isExpanded ? styles.expanded : styles.collapsed
          }`}
          aria-label="Create New"
          onClick={() => handleNavMenuClicked('create-new')}
          type="button"
          colorScheme="primary"
          variant="outline"
          size="lg"
          isDisabled={!allowStartNew}
        >
          <RiAddFill size={20} />
          <span className={styles.text}>{translate('create_new')}</span>
        </Button>
      </div>
      <NavSection
        items={userMenuItems}
        isExpanded={isExpanded}
        onNavMenuClicked={handleNavMenuClicked}
        className={styles['users-menu']}
        activeMenu={activeMenu}
      />
      {isAdminUser && (
        <NavSection
          items={adminMenuItems}
          isExpanded={isExpanded}
          onNavMenuClicked={handleNavMenuClicked}
          className={styles['admin-menu']}
          activeMenu={activeMenu}
        />
      )}
      <NavSection
        items={footerMenuItems}
        isExpanded={isExpanded}
        onNavMenuClicked={handleNavMenuClicked}
        className={styles['footer-menu']}
      />
    </nav>
  );
};

NavigationPanel.displayName = 'NavigationPanel';

export default React.memo(NavigationPanel);
