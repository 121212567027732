import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackContentConfidenceScore
 * @description this method tracks the confidence score against the form given
 * @param {string} formName (form name) [name of the form]
 * @param {number} score (confidence score) [ confidence score at the time of content generate / regenerate / partial-regenerate ]
 * @param {number} questionIndex (question Index) [ question of the index according to the form type ]
 */
export const trackContentConfidenceScore = (
  formName: string,
  score: number,
  questionIndex: number
) => {
  trackFormInput(
    `${formName}`,
    ANALYTICS.STEP,
    'confidence score',
    `${score}`,
    `${questionIndex}`
  );
};

/**
 * @method trackConfidenceScoreForBrand
 * @description this method tracks the confidence score against selected brand
 * @param {string} formName (form name) [name of the form]
 * @param {string} brand (brand name) [ selected brand value ]
 * @param {number} score (confidence score) [ confidence score value ]
 */
export const trackConfidenceScoreForBrand = (
  formName: string,
  brand: string,
  score: number
) => {
  trackFormInput(`${formName}`, ANALYTICS.STEP, `${brand}`, `${score}`, '1');
};

/**
 * @method trackConfidenceScoreForPrimaryMessage
 * @description this method tracks the confidence score against selected primary message
 * @param {string} formName (form name) [name of the form]
 * @param {string} primaryMessage (primary message name) [ selected primary message  ]
 * @param {number} score (confidence score) [ confidence score value ]
 */
export const trackConfidenceScoreForPrimaryMessage = (
  formName: string,
  primaryMessage: string,
  score: number
) => {
  trackFormInput(
    `${formName}`,
    ANALYTICS.STEP,
    `${primaryMessage}`,
    `${score}`,
    '2'
  );
};
