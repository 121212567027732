import { ANALYTICS } from '../../constants/common';
import { trackCustomLinkUsage, trackFormInput } from './analyticEvents';

/**
 * @method trackReferencesPanelOpen
 * @description this method tracks the page level translation
 * @param {string} formName (form name) [name of the form]
 * @param {string} selectedBrand (selected Brand) [selected Brand value]
 * @param {string} selectedContentType (selected contenttype) [selected contenttype value]
 */
export const trackReferencesPanelOpen = (
  formName: string,
  selectedBrand: string,
  selectedContentType: string
) => {
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${selectedBrand}`, '1');
  trackFormInput(
    formName,
    ANALYTICS.STEP,
    'channel',
    `${selectedContentType}`,
    '2'
  );
};

/**
 * @method trackReferenceLinkClick
 * @description This method tracks the  claims and refernces link click
 */
export const trackReferenceLinkClick = (referencesLinkName: string) => {
  trackCustomLinkUsage(referencesLinkName, 'external');
};
