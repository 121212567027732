/**
 * @description This file has methods to trigger analytic events for copy feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackCopyContent
 * @description this method track type of content-copy with selected brand and channel
 * @param {string} formName (form name) [name of the form]
 * @param {string} brand (selected brand name) [name of the brand selected in the prompt form]
 * @param {string} channel (selected channel name) [name of the channel selected in the prompt form]
 */
export const trackCopyContent = (
  formName: string,
  brand: string,
  channel: string
) => {
  trackFormInput(formName, ANALYTICS.STEP, 'brand', `${brand}`, '1');
  trackFormInput(formName, ANALYTICS.STEP, 'channel', `${channel}`, '2');
};
