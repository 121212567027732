import get from 'lodash/get';
import {
  ApiError,
  CannedParametersResult,
  ContentResult,
  FeedbackResult,
  GeneratedContentResponse,
  GeneratedContentResult,
  GetGeneratedContent,
  ImagesResult,
  ReferencesResponse,
  ReferencesResult,
  TrackContentCopyResult,
} from '../types/Content';
import {
  FeedbackData,
  MarketingFormField,
  PregenerateClaimsFeild,
  SecondaryFormApiField,
  VersionPayload,
} from '../types/MarketingBox';
import { httpClient, HTTPClientRequestPayload } from '../utils/httpClient';
import {
  PREGENERATE,
  GENERATE,
  REGENERATE,
  FEEDBACK,
  TRACK_CONTENT_COPY,
  PARTIAL_REGENERATE,
  RECOMMEND_IMAGES,
  CONTENT_ASSET,
  LOCALIZATION_TRANSLATE,
  LOCALIZATION_LANGUAGES,
  CANNED_PARAMETERES_BRANDS,
  CANNED_PARAMETERES_AUDIENCES,
  CANNED_PARAMETERES_TONES,
  CANNED_PARAMETERES_CHANNELS,
  CANNED_PARAMETERES_SECTIONS,
  CANNED_PARAMETERES_SEGMENTS,
  CANNED_PARAMETERES_PLATFORMS,
  CANNED_PARAMETERES_PRIMARY_MESSAGES,
  CONTENT_CONFIG,
  CLAIMS_REFERENCES,
  WF_TASK_DETAILS,
  CANNED_PARAMETERES_INDICATIONS,
  CANNED_PARAMETERES_COUNTRIES,
  CANNED_PARAMETERES_MODULES,
  GET_GENERATED_CONTENT,
} from '../constants/apiRoutes';
import Logger from '../utils/logger';
import { ANALYTICS, ContentType } from '../constants/common';
import {
  getTransformedBannerContent,
  getTransformedDSPData,
  getTransformedPromptData,
  // getTransformedRegenerationSupportedEmailData,
  getTransformedEmailModuleData,
  getTransformedSocialData,
} from '../utils/apiResponseTransformers';
import { getPrimaryLanguage } from './SSO';
import { AvialableLanguageResult } from '../types/Language';
import { GeneratedContent } from '../types/transformedData/MarkettingContentGeneration';
import { FeedbackResponseData } from '../types/apiResponseData/MarkettingContentGeneration';
import { FormFields, FormFieldsPayload } from '../types/PromptForm';
import {
  trackGenerateContentComplete,
  trackGenerateContentInitiate,
  trackSystemErrors,
} from './Analytics';
import {
  WfTaskDetailsResponseData,
  WfTaskDetailsResult,
} from '../types/WfData';
import { ClaimsResult, PrefetchedClaimsResponse } from '../types/Claims';
import { getMappedName } from '../store/markettingContentGeneration/helpers/common';
import { marketingEnhancementDefaults } from '../context/MarkettingPromptContext/constants';
import { getContentSessionId } from '../utils/commonUtils';

const handleError = (): GeneratedContent => {
  const errorResponse = {
    success: false,
  };
  return errorResponse;
};

const getGeneratedContentFormatted = (
  contentType: string,
  data: ContentResult['data'],
  lang: string = getPrimaryLanguage()
): GeneratedContent => {
  switch (contentType) {
    case ContentType.BANNER:
      return {
        success: true,
        data: {
          id: data.id,
          score: {
            value: data.score.value,
          },
          markdownText: '',
          content: getTransformedBannerContent(
            get(data, 'generatedContent', []),
            lang
          ),
        },
      };

    // case ContentType.EMAIL:
    //   return {
    //     success: true,
    //     data: {
    //       id: data.id,
    //       score: {
    //         value: data.score.value,
    //       },
    //       markdownText: '',
    //       content: getTransformedRegenerationSupportedEmailData(data, lang),
    //     },
    //   };

    case ContentType.EMAIL:
      return {
        success: true,
        data: {
          id: data.id,
          score: {
            value: data.score.value,
          },
          markdownText: '',
          content: getTransformedEmailModuleData(data, lang),
        },
      };

    case ContentType.DSP:
      return {
        success: true,
        data: {
          id: data.id,
          score: {
            value: data.score.value,
          },
          markdownText: getTransformedDSPData(data, lang),
        },
      };

    case 'social media':
    case 'social':
      return {
        success: true,
        data: {
          id: data.id,
          score: {
            value: data.score.value,
          },
          markdownText: '',
          content: getTransformedSocialData(data, lang),
        },
      };

    default:
      return {
        success: true,
        data: {
          id: data.id,
          markdownText: JSON.stringify(data),
          score: {
            value: data.score.value,
          },
        },
      };
  }
};

export const fetchPreGeneratedContent = async (
  marketingFormField: MarketingFormField
): Promise<ClaimsResult> => {
  const {
    brand,
    indication,
    country,
    targetAudience,
    segment,
    contentType,
    tone,
    topics,
    platform,
    emailModules,
  } = marketingFormField;
  const payload: HTTPClientRequestPayload = {
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    channel: contentType,
    tone,
    topic: topics,
    platform,
    emailModules: emailModules?.split(','),
  };
  try {
    const response = await httpClient.post<PrefetchedClaimsResponse>(
      PREGENERATE,
      payload
    );
    const { data, success } = response as PrefetchedClaimsResponse;
    if (success && data) {
      return { success, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fetchGeneratedContent = async (
  marketingFormField: MarketingFormField & PregenerateClaimsFeild
): Promise<GeneratedContent> => {
  const {
    brand,
    indication,
    country,
    targetAudience,
    segment,
    sections,
    contentType,
    tone,
    topics,
    platform,
    useSubstituteClaims,
    claims,
    emailModules,
  } = marketingFormField;
  const payload: HTTPClientRequestPayload = {
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    sections,
    channel: contentType,
    tone,
    topic: topics,
    platform,
    useSubstituteClaims,
    claims,
    emailModules: emailModules?.split(','),
    contentSessionId: getContentSessionId(),
  };
  // Tracking analytics for Generate API initiation
  trackGenerateContentInitiate(ANALYTICS.GENERATE_FORM_API_NAME);
  try {
    const response = await httpClient.post<ContentResult>(GENERATE, payload);
    const { data, success } = response as ContentResult;
    if (success && data) {
      // Tracking analytics for Generate API completion
      trackGenerateContentComplete(ANALYTICS.GENERATE_FORM_API_NAME);
      return getGeneratedContentFormatted(contentType, data);
    }
    // Tracking analytics for system errors from generate API
    trackSystemErrors(ANALYTICS.GENERATE_FORM_API_NAME);
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    // Tracking analytics for system errors from generate API
    trackSystemErrors(ANALYTICS.GENERATE_FORM_API_NAME);
    return handleError();
  }
};

const getExclusionValue = (exclusions: string): string[] => {
  if (!exclusions || !exclusions.trim()) {
    return [];
  }

  return exclusions?.split(',').map((str) => str.trim());
};

export const fetchRegeneratedContent = async (
  marketingFormField: MarketingFormField &
    SecondaryFormApiField &
    VersionPayload
): Promise<GeneratedContent> => {
  const {
    brand,
    indication,
    country,
    targetAudience,
    segment,
    sections,
    contentType,
    tone,
    platform,
    topics,
    toneStyle,
    exclusions,
    inclusions,
    versionIds = [],
    parentId,
    emailModules,
  } = marketingFormField;

  const payload: HTTPClientRequestPayload = {
    parentId,
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    sections,
    channel: contentType,
    tone,
    platform,
    topic: topics,
    versionIds,
    emailModules: emailModules?.split(','),
    inclusions,
    contentSessionId: getContentSessionId(),
  };

  if (toneStyle) {
    payload.toneLevel = toneStyle;
  } else {
    // get the default tone level
    payload.toneLevel = getMappedName(
      marketingEnhancementDefaults.currentAvailableToneStyleOptions,
      marketingEnhancementDefaults.currentSelectedToneStyle
    );
  }

  if (exclusions) {
    const exclusionsValue = getExclusionValue(exclusions || '');
    payload.exclusions = exclusionsValue;
  } else {
    payload.exclusions = [];
  }

  // Tracking analytics for Regenerate API initiation
  trackGenerateContentInitiate(ANALYTICS.REGENERATE_FORM_API_NAME);
  try {
    const response = await httpClient.post<ContentResult>(REGENERATE, payload);
    const { data, success } = response as ContentResult;

    if (success && data) {
      // Tracking analytics for Regenerate API completion
      trackGenerateContentComplete(ANALYTICS.REGENERATE_FORM_API_NAME);
      return getGeneratedContentFormatted(contentType, data);
    }
    // Tracking analytics for system errors from regenerate API
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_API_NAME);
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    // Tracking analytics for system errors from regenerate API
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_API_NAME);
    return handleError();
  }
};

export const loadPromptFieldOptions = async (
  formField: FormFields,
  payload: FormFieldsPayload
) => {
  const queryParams = () =>
    Object.entries(payload)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
      )
      .join('&');

  const cannedParamsUrls: Record<string, string> = {
    [FormFields.BRAND]: CANNED_PARAMETERES_BRANDS,
    [FormFields.INDICATION]: CANNED_PARAMETERES_INDICATIONS,
    [FormFields.COUNTRY]: CANNED_PARAMETERES_COUNTRIES,
    [FormFields.AUDIENCE]: CANNED_PARAMETERES_AUDIENCES,
    [FormFields.TONE]: CANNED_PARAMETERES_TONES,
    [FormFields.PRIMARYMESSAGE]: CANNED_PARAMETERES_PRIMARY_MESSAGES,
    [FormFields.CHANNEL]: CANNED_PARAMETERES_CHANNELS,
    [FormFields.SECTION]: CANNED_PARAMETERES_SECTIONS,
    [FormFields.SEGMENT]: CANNED_PARAMETERES_SEGMENTS,
    [FormFields.PLATFORM]: CANNED_PARAMETERES_PLATFORMS,
    [FormFields.MODULE]: CANNED_PARAMETERES_MODULES,
  };

  const url = `${cannedParamsUrls[formField]}?${queryParams()}`;
  try {
    const response = await httpClient.get<CannedParametersResult>(url);
    const { data, success, message } = response as CannedParametersResult;
    return { success, message, data };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return handleError();
  }
};

export const updateFeedback = async (
  feedbackPayload: FeedbackData
): Promise<FeedbackResponseData> => {
  const payload: HTTPClientRequestPayload = {
    ...feedbackPayload,
  };

  try {
    const response = await httpClient.post<FeedbackResult>(FEEDBACK, payload);
    const { data, success } = response as FeedbackResult;

    if (success && data) {
      return { success };
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const trackContentCopy = async (id: string, parentId: string) => {
  const payload: HTTPClientRequestPayload = {
    id,
    parentId,
  };

  try {
    const response = await httpClient.post<TrackContentCopyResult>(
      TRACK_CONTENT_COPY,
      payload
    );
    const { success } = response as TrackContentCopyResult;

    if (success) {
      return { data: {}, success };
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const fetchAvailableLanguage = async () => {
  try {
    const response = await httpClient.get<AvialableLanguageResult>(
      LOCALIZATION_LANGUAGES
    );
    const { data, success, message } = response as AvialableLanguageResult;
    return { success, message, data };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return handleError();
  }
};

export const fetchTranslatedContent = async (
  contentId: number,
  languageId: string,
  contentType: string,
  optionId?: string,
  sections?: string[]
): Promise<GeneratedContent> => {
  const payload: HTTPClientRequestPayload = {
    id: contentId,
    languageId,
    channel: contentType,
  };

  if (optionId) {
    const translateOptions = [
      {
        optionId,
        ...(Array.isArray(sections) &&
          sections.length > 0 && { fields: [...sections] }),
      },
    ];

    payload.translateOptions = translateOptions;
  }

  try {
    const response = await httpClient.post<ContentResult>(
      LOCALIZATION_TRANSLATE,
      payload
    );
    const { data, success } = response as ContentResult;
    if (success && data) {
      return getGeneratedContentFormatted(contentType, data, languageId);
    }
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError();
  }
};

interface RegenerateSectionDetails {
  optionId: string;
  sections?: string[];
}

export const fetchPartialRegeneratedContent = async (
  marketingFormField: MarketingFormField &
    SecondaryFormApiField &
    VersionPayload & { contentId: string },
  regenerateSectionDetails: RegenerateSectionDetails
): Promise<GeneratedContent> => {
  const {
    brand,
    indication,
    country,
    targetAudience,
    segment,
    sections,
    contentType,
    tone,
    topics,
    exclusions,
    inclusions,
    toneStyle,
    versionIds = [],
    parentId,
    contentId,
    emailModules,
  } = marketingFormField;
  const payload: HTTPClientRequestPayload = {
    parentId,
    brand,
    indication,
    country,
    targetAudience,
    persona: segment,
    sections,
    channel: contentType,
    tone,
    topic: topics,
    versionIds,
    contentId,
    emailModules: emailModules?.split(','),
    inclusions,
    contentSessionId: getContentSessionId(),
  };

  if (toneStyle) {
    payload.toneLevel = toneStyle;
  } else {
    // get the default tone level
    payload.toneLevel = getMappedName(
      marketingEnhancementDefaults.currentAvailableToneStyleOptions,
      marketingEnhancementDefaults.currentSelectedToneStyle
    );
  }

  if (exclusions) {
    const exclusionsValue = getExclusionValue(exclusions || '');
    payload.exclusions = exclusionsValue;
  } else {
    payload.exclusions = [];
  }

  payload.regenerateOptions = [
    {
      optionId: regenerateSectionDetails.optionId,
      fields: regenerateSectionDetails.sections,
    },
  ];

  try {
    const response = await httpClient.post<ContentResult>(
      PARTIAL_REGENERATE,
      payload
    );
    const { data, success } = response as ContentResult;

    if (success && data) {
      return getGeneratedContentFormatted(contentType, data);
    }
    return handleError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError();
  }
};

// image recommendation

const getImagesReferenceFrameIndex = (data: ImagesResult['data']) => {
  const { imagesReferenceFrame, frames = [] } = data.content[0];

  return (
    frames.findIndex((frame) => frame.id === imagesReferenceFrame) + 1
  ).toString();
};

const getGeneratedImagesFormatted = (
  contentType: string,
  data: ImagesResult['data']
): GeneratedImageContent => {
  switch (contentType) {
    case ContentType.BANNER:
      return {
        success: true,
        data: {
          optionId: data.content[0].optionId,
          imagesReferenceFrame: getImagesReferenceFrameIndex(data),
          imageUrls: data.content[0].frames
            .filter(
              (item) =>
                item.recommendedImages.length > 0 &&
                item.recommendedImages[0].imageName &&
                item.recommendedImages[0].imageName.trim() !== ''
            )
            .map((item) => item.recommendedImages[0].imageName),
        },
      };
    case 'social':
      return {
        success: true,
        data: {
          optionId: data.content[0].optionId,
          imageUrls: data.content[0].recommendedImages || [],
        },
      };
    case ContentType.EMAIL:
      return {
        success: true,
        data: {
          optionId: data.content[0].optionId,
          imageUrls: data.content[0].recommendedImages || [],
        },
      };
    default:
      return {
        success: false,
      };
  }
};

interface GeneratedImageContent {
  success: boolean;
  data?: {
    optionId: string;
    imagesReferenceFrame?: string;
    imageUrls: string[];
  };
}

const handleImagesError = (): GeneratedImageContent => {
  const errorResponse = {
    success: false,
  };

  return errorResponse;
};

export const fetchRecommendedImages = async (
  formFields: { brand: string; channel: string; contentId: string },
  regenerateSectionDetails: RegenerateSectionDetails
): Promise<GeneratedImageContent> => {
  const payload: HTTPClientRequestPayload = {
    brand: formFields.brand,
    channel: formFields.channel,
    contentId: formFields.contentId,
  };

  payload.imageRecommendOptions = [
    {
      optionId: regenerateSectionDetails.optionId,
      fields: regenerateSectionDetails.sections,
    },
  ];

  try {
    const response = await httpClient.post<ContentResult>(
      RECOMMEND_IMAGES,
      payload
    );
    const { data, success } = response as ImagesResult;

    if (success && data) {
      return getGeneratedImagesFormatted(payload.channel, data);
    }
    return handleImagesError();
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleImagesError();
  }
};

type DownloadFileResponseData = {
  success: boolean;
  data?: { content: string; fileName?: string };
};

export interface UploadFileResult {
  success: boolean;
  message: string;
  errors?: string[];
  data: unknown;
  headers?: Record<string, string>;
  status: number;
}

export const fetchContentAsset = async (
  imageName: string
): Promise<DownloadFileResponseData | ApiError> => {
  try {
    const headers = {
      Accept: 'application/octet-stream',
    };
    const response = (await httpClient.get<UploadFileResult>(
      CONTENT_ASSET(imageName),
      {},
      headers,
      'arraybuffer'
    )) as UploadFileResult;

    const { data, success, headers: responseHeaders } = response;

    if (success && data && responseHeaders) {
      return {
        data: { content: data, fileName: 'imageName' },
        success,
      } as DownloadFileResponseData;
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const fetchReferences = async (
  contentId: string
): Promise<ReferencesResponse> => {
  Logger.info(contentId);
  try {
    const response = await httpClient.get<ReferencesResult>(
      CLAIMS_REFERENCES(contentId)
    );

    const { data, success } = response as ReferencesResult;
    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return {
      success: false,
    };
  }
};

export type ContentConfig = {
  success: boolean;
  data: {
    contentConfig: {
      recommendImages: false;
    };
  };
};

export const fetchContentConfig = async (formFields: MarketingFormField) => {
  try {
    const response = (await httpClient.get<ContentConfig>(
      CONTENT_CONFIG(
        formFields.brand,
        formFields.targetAudience,
        formFields.contentType
      ),
      {}
    )) as ContentConfig;
    const { data, success } = response;

    if (success && data) {
      return { success, data };
    }

    return handleError();
  } catch (error) {
    const getError = error as ApiError;
    Logger.error(new Error(getError.message));
    return handleError();
  }
};

export const fetchWFTaskDetails = async (
  taskId: string,
  projectId: string
): Promise<WfTaskDetailsResponseData> => {
  try {
    const response = await httpClient.get<WfTaskDetailsResult>(
      WF_TASK_DETAILS(taskId, projectId)
    );
    const { success, data } = response as WfTaskDetailsResult;

    if (success && data) {
      return { success, data: getTransformedPromptData(data) };
    }
    return { success: false };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return { success: false };
  }
};

export const getGeneratedContent = async ({
  contentSessionId,
  contentId,
}: GetGeneratedContent): Promise<GeneratedContentResponse> => {
  try {
    const response = await httpClient.get<GeneratedContentResult>(
      GET_GENERATED_CONTENT(contentId, contentSessionId)
    );
    const { data, success } = response as GeneratedContentResult;
    if (success && data) {
      return {
        success,
        data: data.generatedContentData,
      };
    }
    return handleError() as GeneratedContentResponse;
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return handleError() as GeneratedContentResponse;
  }
};
