import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/root';

function App() {
  return <RouterProvider router={router} />;
}

App.displayName = 'App';
export default App;
