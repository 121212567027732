import { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Provider as JotaiProvider } from 'jotai';
import { SSOProvider } from './SSOProvider';
import SSOCallback from '../components/pages/SSOCallback';
// import Home from '../components/pages/Home';
import { PAGE_ROUTES } from '../constants/common';
import MarketingApplication from '../components/pages/MarketingApplication';
import markettingContentGenerationStore from '../store/markettingContentGeneration';

const JotaiDebugHooks = lazy(
  () => import('../components/widgets/JotaiDebugHooks')
);

const shouldShowDebugger =
  sessionStorage.getItem('pfz-cggenai-debug-secret') ===
  process.env.REACT_APP_STATE_DEBUG_KEY;

export const router = createBrowserRouter([
  {
    path: PAGE_ROUTES.DEFAULT,
    element: <Navigate to={PAGE_ROUTES.GENERATE_CONTENT} replace />,
    /** Commented as part of CSUP-4287, to ensure that the user lands directly on the Generate page. */
    // (
    //   <SSOProvider>
    //     <Home />
    //   </SSOProvider>
    // ),
  },
  {
    path: PAGE_ROUTES.GENERATE_CONTENT,
    element: (
      <SSOProvider>
        <JotaiProvider store={markettingContentGenerationStore}>
          {shouldShowDebugger && <JotaiDebugHooks />}
          <MarketingApplication isLoadMarketingWidget />
        </JotaiProvider>
      </SSOProvider>
    ),
  },
  {
    path: PAGE_ROUTES.ACCESS_MANAGEMENT,
    element: (
      <SSOProvider>
        <MarketingApplication isLoadAccessWidget />
      </SSOProvider>
    ),
  },
  // {
  //   path: PAGE_ROUTES.SUMMARIZE_CONTENT,
  //   element: (
  //     <SSOProvider>
  //       <MedicalDashboard />
  //     </SSOProvider>
  //   ),
  // },
  {
    path: PAGE_ROUTES.CALLBACK,
    element: <SSOCallback />,
  },
  {
    path: PAGE_ROUTES.ANY,
    element: <Navigate to="/" replace />,
  },
]);
