/**
 * @description This file is to track the usage of export content feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackExportToDocUsage
 * @description tracks the usage of export content feature
 * @param {string} brand (brand name)  [brand used to create the content]
 * @param {string} channel (channel name) [channel used to create the content]
 * @param {boolean} isSuccess [flag to check whether the content export is a success/failure]
 */
export const trackExportToDocUsage = (
  brand: string,
  channel: string,
  isSuccess: boolean
) => {
  const formName = isSuccess
    ? ANALYTICS.EXPORT_TO_DOC_SUCCESS_FORM_NAME
    : ANALYTICS.EXPORT_TO_DOC_ERROR_FORM_NAME;

  trackFormInput(formName, ANALYTICS.NUMBER_ONE, 'brand', brand, '1');
  trackFormInput(formName, ANALYTICS.NUMBER_ONE, 'channel', channel, '2');
};
