import React from 'react';
import styles from './NavMenuItem.module.css';

// Props type for NavMenuItem component
interface NavMenuItemProps {
  icon: React.ReactNode;
  text: string;
  isExpanded: boolean;
  handleMenuItemClick: () => void;
  className?: string;
  isActive: boolean;
}

// NavMenuItem component
const NavMenuItem: React.FC<NavMenuItemProps> = ({
  icon,
  text,
  isExpanded,
  handleMenuItemClick,
  className,
  isActive = false,
}) => {
  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      handleMenuItemClick();
    }
  };

  return (
    <li
      className={`${styles['nav-menu-item']} ${
        isExpanded ? styles.expanded : ''
      } ${className} ${isActive ? styles.active : ''}`}
      role="menuitem"
      tabIndex={0}
      onClick={handleMenuItemClick}
      aria-expanded={isExpanded}
      onKeyUp={onKeyUpHandler}
    >
      {icon}
      {isExpanded && <span className={styles.text}>{text}</span>}
    </li>
  );
};

export default NavMenuItem;
