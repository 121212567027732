import { ImageInfoConfig } from '../constants/common';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type BannerFrameType = {
  body: string;
};

export type BannerFramesListType = {
  frames: BannerFrameType[];
  optionId: string;
};

export interface ContentResult {
  message: '';
  status: 200;
  statusText: '';
  success: true;
  data: {
    id: string;
    // parentId?: string;
    contentType: ContentType;
    score: {
      value: number;
    };
    status: string;
    generatedContent: BannerFramesListType[];
  };
}

export interface ImagesResult {
  message: '';
  status: 200;
  statusText: '';
  success: true;
  data: {
    id: string;
    channel: string;
    content: [
      {
        optionId: string;
        // for social media only
        recommendedImages?: string[];
        imagesReferenceFrame: string;
        frames: [
          {
            id: string;
            recommendedImages: [
              {
                imageName: string;
              },
            ];
          },
        ];
      },
    ];
  };
}

export interface CannedParametersResult {
  message?: '';
  status?: 200;
  statusText?: '';
  success?: true;
  data: CannedPramsResponseType;
}

export type CannedParams = {
  id: string;
  label: string;
  name?: string;
  recommend?: boolean;
};

export type CannedPramsResponseType = {
  items: CannedParams[];
};

export interface ApiError {
  success: boolean;
  message: string;
  errors?: string[];
  data: unknown;
  status: number;
}

export interface FeedbackResult {
  success: boolean;
  message: string;
  errors?: string[];
  data: unknown;
  status: number;
}

export interface TrackContentCopyResult {
  success: true;
  message: string;
  errors: string[];
  data: unknown;
}

export enum ContentType {
  BANNER = 'Banner',
  DSP = 'DSP',
  EMAIL = 'Email',
  SOCIAL = 'Social Media',
}

export type SegmentType = {
  subHeader: string;
  texts: string[];
};

export type SectionsType = {
  name: string;
  body: string | SegmentType[];
};

export enum ActionNames {
  COPY = 'COPY',
  COPY_TEXT = 'COPY_TEXT',
  REGENERATE = 'REGENERATE',
}

export enum ActionLevel {
  SEGMENT = 'segment',
  SECTION = 'section',
}

export interface Action {
  name: string;
  label: string;
  enabled: boolean;
}

export interface ContentsDataType {
  title: string;
  actionLevel?: ActionLevel;
  sections: SectionsType[];
}

export interface ContentTypeImage {
  name: string;
  altText: string;
  frameId: string;
  id: string;
}

export interface BannerContentType {
  title: string;
  frames: string[];
  optionId: string;
  isLoading: boolean;
}

export interface EmailContentSection {
  path: string;
  name: string;
  title: string;
  markdownText: string;
  isLoading?: boolean;
}
export interface EmailContent {
  optionId: string;
  topSection: {
    title: string;
    preHeader: EmailContentSection;
    subjectLineOptions: EmailContentSection;
    previewTextOptions: EmailContentSection;
    isLoading?: boolean;
  };
  emailBody: {
    title: string;
    introCopy: EmailContentSection;
    callToActionsOption: EmailContentSection;
  };
  isLoading?: boolean;
}

export type SocialContentForm = {
  name: string;
  title: string;
  isLoading: boolean;
  headline: {
    name: string;
    title: string;
    text: string;
  };
  body: {
    name: string;
    title: string;
    text: string;
  };
  hashtags: {
    name: string;
    title: string;
    text: string;
  };
};

export type SocialContent = {
  title: string;
  optionId: string;
  isLoading: boolean;
  shortForm: SocialContentForm;
  longForm: SocialContentForm;
};

export type GeneratedSocialContent = {
  optionId: string;
  shortForm: {
    headline: string;
    body: string;
    hashtags: string[];
  };
  longForm: {
    headline: string;
    body: string;
    hashtags: string[];
  };
};

export interface ClaimReferences {
  id: string;
  code: string;
  description: string;
  documentNames: string[];
  referenceLink: string;
  status: string;
  isSubstitute: boolean;
}

export interface ReferencesResponseData {
  id: string;
  channel: string;
  claimReferences: ClaimReferences[];
}

export interface ReferencesResult {
  success: boolean;
  message: string;
  errors?: string[];
  data: ReferencesResponseData | null;
  status: number;
}

export interface ReferencesResponse {
  success: boolean;
  data?: ReferencesResponseData | [];
}

export interface AnalyticsData {
  brand: string;
  contentType: string;
}

export type ImageRecomendationInfoType = {
  type: ImageInfoConfig;
  labelText: string;
  ctaText: string;
};

export type EmailModuleContentResponse = {
  success: boolean;
  data: EmailModuleContent;
};

export type EmailModuleOptionsContent = {
  status: string;
  optionId: string;
  header?: string;
  preHeader?: string;
  body?: string;
  closingBody?: string;
  callToAction?: string;
  subheader?: string;
  bulletedCopy: string[];
  subjectLine?: string;
  previewText?: string;
  recommendedImages?: string[];
};

export type EmailModuleAttributes = {
  id: string;
  name: string;
  code: string;
  sequence: number;
};

export type EmailModuleGeneratedContent = {
  name: string;
  contentSessionId: string;
  attributes: EmailModuleAttributes[];
  generatedModuleContent: EmailModuleOptionsContent[];
};

export type EmailModuleContent = {
  id: string;
  contentType: string;
  score: {
    value: number;
  };
  generatedContent: EmailModuleGeneratedContent[];
};

export type GetGeneratedContent = {
  contentSessionId: string;
  contentId: string;
};

type GeneratedModuleContent = {
  name: string;
  attributes: EmailModuleAttributes[];
  generatedModuleContent: EmailModuleOptionsContent[];
};

type Score = {
  value: number;
};

export type GeneratedContentData = {
  id: string;
  parentId: string;
  channel: string;
  versionSequence: number;
  score: Score;
  generatedContent: GeneratedModuleContent[];
};
interface GeneratedContentResultData {
  contentSessionId: string;
  generatedContentData: GeneratedContentData[];
}

export interface GeneratedContentResult {
  success: boolean;
  message: string;
  errors: string[];
  data: GeneratedContentResultData;
}

export type GeneratedContentResponse = {
  success: boolean;
  data?: GeneratedContentData[];
};
