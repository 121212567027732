import { get, isEmpty } from 'lodash';
import { RESET } from 'jotai/utils';
import store from '..';
import { getPrimaryLanguage } from '../../../services/SSO';
import { contentListStateSegment } from '../stateSegments/contentListSegment';
import {
  currentAvailableLanguagesStateSegment,
  currentSelectedLanguageStateSegment,
} from '../stateSegments/availableLanguagesStateSegment';
import {
  currentLanguageTabStateSegment,
  currentLanguageTabsListStateSegment,
} from '../stateSegments/languageTabsStateSegment';
import { convertTextToLowerCase } from '../../../utils/convertTextToLowerCase';
import { Option } from '../../../types/SelectInput';
import {
  MarketingFormField,
  SecondaryFormApiField,
  VersionPayload,
} from '../../../types/MarketingBox';
import { ParamsUsed } from '../../../types/store/markettingContentGeneration/majorProcessActions';
import { contentImagesStateSegment } from '../stateSegments/contentImagesStateSegment';
import {
  ContentType,
  MAX_EMAIL_MODULES_SELECTION_LIMIT,
} from '../../../constants/common';
import { contentTranslationStateSegment } from '../stateSegments/translationSegment';
import { EmailContent } from '../../../types/Content';
import { contentPaginationStateSegment } from '../stateSegments/contentPaginationStateSegment';
import { GeneratedContentDataType } from '../../../types/transformedData/MarkettingContentGeneration';
import { preGenerateCliamsStateSegment } from '../stateSegments/preGenerateClaimsStateSegment';
import {
  emailContentParentId,
  moduleContentsStateSegment,
  selectedModulesStateSegment,
} from '../stateSegments/emailModulesStateSegment';
import {
  createUniqueModuleKey,
  getConvertedName,
} from '../../../utils/commonUtils';
import { SelectedModule } from '../../../types/store/markettingContentGeneration/emailModules';
import imagesDataStateSegment from '../stateSegments/imagesDataStateSegment';
import { contentFeedbackStateSegment } from '../stateSegments/feedbackSegment';
import { contentClaimsStateSegment } from '../stateSegments/contentClaimsStateSegment';
import { refineOptionsStateSegment } from '../stateSegments/refineOptionsStateSegment';

export const getMappedLabel = (collection: Option[], id: string) => {
  const { label } = collection.find((item) => item.id === id) || { label: '' };
  return convertTextToLowerCase(label);
};

export const getMappedName = (collection: Option[], selectedName: string) => {
  const { name } = collection.find(
    (item) => item.name?.toLowerCase() === selectedName.toLowerCase()
  ) || { name: '' };
  return convertTextToLowerCase(name);
};

export const getVersionIds = () => {
  const versionIds = store
    .get(contentListStateSegment)
    .contentList.map((contentItem) =>
      get(contentItem, `${getPrimaryLanguage()}.id`, '')
    );
  return versionIds;
};

export const getParentId = () => {
  const [parentId] = getVersionIds();
  return parentId;
};

export const getGenerateContentPayload = (
  paramsUsed: ParamsUsed
): MarketingFormField => {
  const selectedBrandLabel = getMappedName(
    paramsUsed.currentAvailableBrandOptions,
    paramsUsed.currentSelectedBrand
  );
  const selectedIndicationName = getMappedName(
    paramsUsed.currentAvailableIndicationOptions,
    paramsUsed.currentSelectedIndication
  );
  const selectedCountryName = getMappedName(
    paramsUsed.currentAvailableCountryOptions,
    paramsUsed.currentSelectedCountry
  );
  const selectedAudienceLabel = getMappedName(
    paramsUsed.currentAvailableAudienceOptions,
    paramsUsed.currentSelectedAudience
  );
  const selectedContentTypeLabel = getMappedName(
    paramsUsed.currentAvailableContentTypeOptions,
    paramsUsed.currentSelectedContentType
  );
  const selectedPersonaLabel = getMappedName(
    paramsUsed.currentAvailableSegmentOptions,
    paramsUsed.currentSelectedSegment
  );

  const selectedSections = get(paramsUsed, 'currentSelectedSections', []);
  const selectedSectionsLabels: string[] = [];

  if (!isEmpty(selectedSections)) {
    selectedSections.forEach((section: string) => {
      selectedSectionsLabels.push(
        getMappedName(paramsUsed.currentAvailableSectionOptions, section)
      );
    });
  }
  const selectedToneLabel = getMappedName(
    paramsUsed.currentAvailableToneOptions,
    paramsUsed.currentSelectedTone
  );

  const selectedPlatform = getMappedName(
    paramsUsed.currentAvailablePlatformOptions,
    paramsUsed.currentSelectedPlatform
  );

  const selectedTopicsLabel = getMappedName(
    paramsUsed.currentAvailableTopicOptions,
    paramsUsed.currentSelectedTopic
  );

  const requestPayload: MarketingFormField = {
    brand: selectedBrandLabel,
    country: selectedCountryName,
    indication: selectedIndicationName === '' ? 'any' : selectedIndicationName,
    targetAudience: selectedAudienceLabel,
    segment: selectedPersonaLabel,
    sections: selectedSectionsLabels as unknown as string, // needs to be corrSected
    contentType: selectedContentTypeLabel,
    tone: selectedToneLabel,
    topics: selectedTopicsLabel,
    platform: selectedPlatform,
    emailModules: paramsUsed.currentSelectedModules,
  };

  return requestPayload;
};

const getMappedOption = (collection: Option[], name: string) => {
  return collection.find(
    (item) => item.name?.toLowerCase() === name.toLowerCase()
  );
};

export const getParamsUsed = (paramsUsed: ParamsUsed) => {
  return {
    brand: getMappedOption(
      paramsUsed.currentAvailableBrandOptions,
      paramsUsed.currentSelectedBrand
    ),
    indication: getMappedOption(
      paramsUsed.currentAvailableIndicationOptions,
      paramsUsed.currentSelectedIndication
    ),
    country: getMappedOption(
      paramsUsed.currentAvailableCountryOptions,
      paramsUsed.currentSelectedCountry
    ),
    audience: getMappedOption(
      paramsUsed.currentAvailableAudienceOptions,
      paramsUsed.currentSelectedAudience
    ),
    contentType: getMappedOption(
      paramsUsed.currentAvailableContentTypeOptions,
      paramsUsed.currentSelectedContentType
    ),
    persona: getMappedOption(
      paramsUsed.currentAvailableSegmentOptions,
      paramsUsed.currentSelectedSegment
    ),
    sections: paramsUsed.currentSelectedSections.map((item: string) =>
      getMappedOption(paramsUsed.currentAvailableSectionOptions, item)
    ),
    tone: getMappedOption(
      paramsUsed.currentAvailableToneOptions,
      paramsUsed.currentSelectedTone
    ),

    platform: getMappedOption(
      paramsUsed.currentAvailablePlatformOptions,
      paramsUsed.currentSelectedPlatform
    ),
    topics: getMappedOption(
      paramsUsed.currentAvailableTopicOptions,
      paramsUsed.currentSelectedTopic
    ),
    toneStyle: getMappedOption(
      paramsUsed.currentAvailableToneStyleOptions,
      paramsUsed.currentSelectedToneStyle
    ),
    exclusions: paramsUsed.currentSelectedExclusion,
    inclusions: paramsUsed.currentSelectedInclusion,
  };
};

export const resetTranslationState = () => {
  store.set(currentSelectedLanguageStateSegment, getPrimaryLanguage());
  store.set(currentLanguageTabStateSegment, getPrimaryLanguage());
  store.set(currentLanguageTabsListStateSegment, []);
};

export const updateLanguageOptions = (langCode: string) => {
  const languageOptions = store.get(currentAvailableLanguagesStateSegment).data;

  const languageOption = languageOptions.find(
    (currentAvailableLanguage: Option) =>
      currentAvailableLanguage.value === langCode
  );

  const primaryLanguageOption: Option = {
    value: 'en',
    label: 'English',
    id: 'en',
  };

  if (languageOption && languageOption.value) {
    const languageOptionsToUpdate: Option[] = [
      languageOption,
      primaryLanguageOption,
    ];

    store.set(currentLanguageTabsListStateSegment, languageOptionsToUpdate);
    store.set(currentLanguageTabStateSegment, languageOption.value);
  }
};

export const setSectionLoader = ({
  langCode,
  optionId,
  showLoader,
}: {
  langCode: string;
  optionId: string;
  showLoader: boolean;
}) => {
  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      sectionLoaders: {
        ...prev.sectionLoaders,
        [`${langCode}-${optionId}`]: showLoader,
      },
    };
  });
};

export const getSectionLoader = (langCode: string, optionId: string) => {
  const sectionLoaderHashmap: Record<string, boolean> = store.get(
    contentListStateSegment
  ).sectionLoaders;

  return sectionLoaderHashmap[`${langCode}-${optionId}`] || false;
};

export const getEmailSectionLoader = () => {
  const langCode = getPrimaryLanguage();
  const currentViewVersion = store.get(contentPaginationStateSegment);
  const { contentList = [] } = store.get(contentListStateSegment);

  const selectedLanguage: GeneratedContentDataType =
    contentList[currentViewVersion][langCode];

  const emailContent = selectedLanguage?.content as EmailContent;

  return {
    isOptionLoading: emailContent.isLoading || false,
    isTopSectionLoading: emailContent.topSection.isLoading || false,
    isCtaLoading: emailContent.emailBody.callToActionsOption.isLoading || false,
    isIntroCopyLoading: emailContent.emailBody.introCopy.isLoading || false,
  };
};

export const setEmailSectionLoaderApplied = (
  currentViewVersion: number,
  path: string,
  value: boolean,
  langCode: string = getPrimaryLanguage()
) => {
  const { contentList = [] } = store.get(contentListStateSegment);

  const { map: translationHashMap } = store.get(contentTranslationStateSegment);

  const currentData = contentList[currentViewVersion];

  let obj: EmailContent;

  if (langCode === getPrimaryLanguage()) {
    obj = get(currentData, `${getPrimaryLanguage()}.content`);
  } else {
    const contentId = get(currentData, `${getPrimaryLanguage()}.id`);
    const { data: currentTranslatedContent } = get(
      translationHashMap,
      contentId,
      {
        data: null,
      }
    );
    obj = currentTranslatedContent?.content as EmailContent;
  }

  // if langcode is not primary language then we will fetch from tanslatesegment map
  // if langcode is primary than obj be same from the language

  if (path === '') {
    obj.isLoading = value;
  } else {
    const keys = path.split('.');
    /* eslint-disable */
    let currentObj: any = obj;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      currentObj = currentObj[key];
    }
    currentObj.isLoading = value;
  }

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      contentList: contentList,
    };
  });
};

export const resetOptionImages = (contentId: string, optionId: string) => {
  store.set(contentImagesStateSegment, (prev) => {
    const imageStateReplica = { ...prev };
    if (
      contentId in imageStateReplica &&
      optionId in imageStateReplica[contentId]
    ) {
      delete imageStateReplica[contentId][optionId];
    }
    return { ...imageStateReplica };
  });
};

export const getContentApiPayloadUsed = (content: unknown) => {
  const apiPayloadUsed = get(
    content,
    `apiPayloadUsed`
  ) as unknown as MarketingFormField &
    SecondaryFormApiField &
    VersionPayload & { contentId: string };
  return apiPayloadUsed;
};

export const getContent = (currentVersion: number) => {
  const contentList = store.get(contentListStateSegment).contentList;
  const content = get(contentList, `[${currentVersion}]`);
  return content;
};

export const getOptionIdByContentType = (
  content: unknown,
  langCode: string,
  contentType: string
) => {
  let optionId = '';
  if (contentType === 'email') {
    optionId = get(content, `${langCode}.data.optionId`, '');
  }
  return optionId;
};

export const getPrefectedClaimListIds = () => {
  const claimsList = store.get(
    preGenerateCliamsStateSegment
  ).preFetchedclaimsList;
  const claimsListIds = claimsList.map((claim) => claim.id);
  return claimsListIds;
};

export const showLoadersBasedOnContentType = (paramsUsed: {
  contentType: ContentType;
  optionId: string;
  currentVersion: number;
  loaderPath: string;
  showLoader: boolean;
  langCode: string;
}) => {
  const { langCode } = paramsUsed;

  if (
    paramsUsed.contentType === ContentType.BANNER ||
    paramsUsed.contentType === ('social' as ContentType) ||
    paramsUsed.contentType === ContentType.SOCIAL ||
    paramsUsed.contentType === ContentType.EMAIL
  ) {
    setSectionLoader({
      langCode: getPrimaryLanguage(),
      optionId: paramsUsed.optionId,
      showLoader: paramsUsed.showLoader,
    });
  }
  // else if (paramsUsed.contentType === ContentType.EMAIL) {
  //   setEmailSectionLoaderApplied(
  //     paramsUsed.currentVersion,
  //     paramsUsed.loaderPath,
  //     paramsUsed.showLoader,
  //     langCode
  //   );
  // }
};

export const isModuleLimitReached = () => {
  const selectedModulesMap = store.get(selectedModulesStateSegment);
  const selectedModulesList = Array.from(selectedModulesMap.values());
  const totalCount = selectedModulesList.reduce(
    (acc, module) => acc + module.count,
    0
  );
  return MAX_EMAIL_MODULES_SELECTION_LIMIT === totalCount;
};

export const selectedModulesCount = () =>
  store.get(moduleContentsStateSegment).size;

export const addModules = (
  { count, name, label }: SelectedModule,
  sequence: number,
  isDisabled: boolean = false
) => {
  const moduleContents = new Map(store.get(moduleContentsStateSegment));

  const uniqueKey = createUniqueModuleKey(name, count);
  moduleContents.set(uniqueKey, {
    moduleKey: getConvertedName(name),
    mapKey: uniqueKey,
    parentContentId: '',
    name,
    label,
    translatedLanguage: getPrimaryLanguage(),
    status: 'Not Started',
    hasErrorGeneratingContent: false,
    hasContentGenerated: false,
    hasImageSelected: false,
    hasOptionSelected: false,
    refinedOptions: [],
    selectedOption: null,
    selectedOptionId: '',
    selectedImage: null,
    selectedImageName: '',
    sequence,
    generatePayload: null,
    isDisabled,
  });

  store.set(moduleContentsStateSegment, moduleContents);
};

export const resetGeneratedContents = () => {
  store.set(contentListStateSegment, RESET);
  resetTranslationState();
  store.set(contentTranslationStateSegment, RESET);
  store.set(contentImagesStateSegment, RESET);
  store.set(imagesDataStateSegment, RESET);
  store.set(contentFeedbackStateSegment, RESET);
  store.set(currentSelectedLanguageStateSegment, RESET);
};

export const resetAllEmailModules = () => {
  resetGeneratedContents();
  store.set(contentPaginationStateSegment, RESET);
  store.set(contentClaimsStateSegment, RESET);
  store.set(refineOptionsStateSegment, RESET);
  store.set(emailContentParentId, RESET);
};
