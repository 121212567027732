import React, { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import styles from './MainLayout.module.css';
import NavigationPanel from '../../widgets/NavigationPanel';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const isNavPanelOpen = get(location, 'state.isNavPanelOpen', false);

  const [isNavOpen, setIsNavOpen] = useState(isNavPanelOpen);
  const [activeMenu, setActiveMenu] = useState('');

  const handleNavMenuToggle = useCallback((value: boolean) => {
    setIsNavOpen(value);
  }, []);

  return (
    <div className={styles['page-layout__wrapper']}>
      <div className={styles['page-layout__col-left']}>
        <NavigationPanel
          isExpanded={isNavOpen}
          onNavToggle={handleNavMenuToggle}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      </div>
      <div className={styles['page-layout__col-right']}>
        <div id="top-nav" />
        <div className={styles['app-main']}>{children}</div>
      </div>
    </div>
  );
};

MainLayout.displayName = 'MainLayout';
export default MainLayout;
