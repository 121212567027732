/**
 * @description This file is to track the usage of download image feature
 */

import { ANALYTICS } from '../../constants/common';
import { trackFormInput } from './analyticEvents';

/**
 * @method trackDownloadImageUsage
 * @description tracks the usage of image download feature
 * @param {string} brand (brand name)  [brand used to create the content]
 * @param {string} channel (channel name) [channel used to create the content]
 */
export const trackDownloadImageUsage = (brand: string, channel: string) => {
  trackFormInput(
    ANALYTICS.DOWNLOAD_IMAGES_FORM_NAME,
    ANALYTICS.NUMBER_ONE,
    'brand',
    brand,
    '1'
  );
  trackFormInput(
    ANALYTICS.DOWNLOAD_IMAGES_FORM_NAME,
    ANALYTICS.NUMBER_ONE,
    'channel',
    channel,
    '2'
  );
};
