import React, { useEffect, useState, useCallback } from 'react';
import {
  startPKCEFlow,
  refreshAccessToken,
  fetchUserInfo,
  setPrimaryLanguage,
} from '../services/SSO';
import SectionLoadingIndicator from '../components/molecules/SectionLoadingIndicator';
import Logo from '../components/atoms/Logo';

interface SSOProviderProps {
  children: React.ReactNode;
}

export const SSOProvider: React.FC<SSOProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  // New function to handle automatic token refresh
  const autoRefreshToken = useCallback(async () => {
    const expiresIn = sessionStorage.getItem('pfz-cggenai-token-expiry');
    const refreshToken = sessionStorage.getItem('pfz-cggenai-token-refresh');

    // Check if we have a refresh token and if the access token is about to expire
    // eslint-disable-next-line radix
    if (refreshToken && expiresIn && parseInt(expiresIn) - Date.now() < 60000) {
      // 60 seconds before expiry
      try {
        await refreshAccessToken(refreshToken);
        setIsLoggedIn(true);
      } catch (error) {
        startPKCEFlow();
      }
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setIsLoggedIn(true);
      setPrimaryLanguage('en');
      (async () => {
        await fetchUserInfo();
      })();
    } else {
      const init = async () => {
        const accessToken = sessionStorage.getItem('pfz-cggenai-token-access');
        const refreshToken = sessionStorage.getItem(
          'pfz-cggenai-token-refresh'
        );
        const expiresAt = sessionStorage.getItem('pfz-cggenai-token-expiry');

        if (!accessToken && !refreshToken) {
          await startPKCEFlow();
          // eslint-disable-next-line radix
        } else if (accessToken && Date.now() > parseInt(expiresAt || '0')) {
          try {
            await refreshAccessToken(refreshToken || '');
            setIsLoggedIn(true);
          } catch {
            await startPKCEFlow();
          }
        } else {
          // if (isEmpty(sessionStorage.getItem('pfz-cggenai-user-givenname'))) {
          await fetchUserInfo();
          // }
          setIsLoggedIn(true);
        }
        // Call autoRefreshToken periodically
        setInterval(autoRefreshToken, 5 * 60000); // check every 5 minutes
      };

      init();
    }
  }, []);

  return isLoggedIn ? (
    <div>{children}</div>
  ) : (
    <div className="preloader_wrapper">
      <Logo />
      <SectionLoadingIndicator text="Getting things ready..." />
    </div>
  );
};
SSOProvider.displayName = 'SSOProvider';
