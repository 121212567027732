import { atom as stateSegment } from 'jotai';

import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';
import getValue from 'lodash/get';
import { contentPaginationStateSegment } from './contentPaginationStateSegment';
import { getPrimaryLanguage } from '../../../services/SSO';

export const contentListStateSegment = stateSegmentWithReset({
  // flags
  isGeneratingContent: false,
  isReGeneratingContent: false,
  isPartialRegenerating: false,
  isRefiningContent: false,
  isCompareModeOn: false,

  // error flags
  errorGeneratingContent: false,
  errorReGeneratingContent: false,
  errorPartialRegenerating: false,
  errorRefiningContent: false,

  // sections flags to show micro loading, <lang-optionid> : true / false
  sectionLoaders: {},

  // error message
  errorMessage: '',

  // content list
  contentList: [],

  retryPromptParams: {},
});
contentListStateSegment.debugLabel = 'contentListStateSegment';

export const sectionLoadersStateSegent = stateSegment(
  (get) => get(contentListStateSegment).sectionLoaders
);

sectionLoadersStateSegent.debugLabel = 'sectionLoadersStateSegent';

export const selectedChannel = stateSegmentWithReset('');
selectedChannel.debugLabel = 'selectedChannel';

export const contentIdStateSegment = stateSegment((get) => {
  const currentViewIndex = get(contentPaginationStateSegment);
  const { contentList } = get(contentListStateSegment);
  const language = getPrimaryLanguage();
  const currentContent = getValue(
    contentList,
    `[${currentViewIndex}].${language}`,
    {
      id: '',
    }
  );
  return currentContent.id;
});

contentIdStateSegment.debugLabel = 'contentIdStateSegment';
